export default function dropdown(config, data){
    return {
        config: config,
        filter: '',
        show: false,
        options: data,
        close() {
            this.show = false;
        },
        open() {
            this.show = true;
            this.filter = '';
        },
        toggle() {
            this.show ? this.close() : this.open();
        },
        get isOpen() { return this.show === true },
        get displayName() {
            const selectedItems = this.options.items.reduce((acc, option, index) => {
                return option.selected ? [...acc, option.label] : acc;
            },[]);
            return selectedItems.length === 0 ? this.options.title : selectedItems.join(', ');
        },
        get outputInputs(){
            return this.isOpen && this.config.output == 'inputs'
        },
        get outputLinks(){
            return this.isOpen && this.config.output == 'links'
        },
        get selectedOptions(){
            return this.options.items.filter(option => option.selected === true );
        },
        get inputName(){
            return this.config.inputName;
        },
        classOption(option) {
            return {
                'cursor-pointer w-full border-gray-100 border-b hover:bg-blue-50': true
            };
        },
        sortedOptions() {
            return this.options.items
                ? this.options.items.filter(option => {
                    const lowercase_filter = this.filter.toLowerCase();
                    return (option.label.toLowerCase().indexOf(lowercase_filter) > -1) || (this.config.show_selected && option.selected)
                }).sort((a,b) => {
                    return b.selected - a.selected;
                })
                : {}
        },
        onOptionClick(option) {
            if (this.config.selection == 'multiple'){
                option.selected = !option.selected;
            }else{
                for(const option of this.options.items){
                    option.selected = false;
                }
                option.selected = true;
            }
        }
    }
}