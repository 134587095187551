export default function password_confirmation(){
    return {
        first_name: '',
        last_name: '',
        password:'',
        confirmation:'',
        get matched() {
            return this.password === this.confirmation;
        },
        get submittable(){
            return this.matched
                && this.password !== ''
                && this.first_name !== ''
                && this.last_name !== ''
        }
    }
}