import Alpine from 'alpinejs';
import '@hotwired/turbo-rails'

import Dropdown from '../components/dropdown'
import PasswordConfirmation from '../components/password_confirmation'

document.addEventListener("alpine:init", () => {
    Alpine.data('dropdown', Dropdown)
    Alpine.data('password_confirmation', PasswordConfirmation)
});

Alpine.start();